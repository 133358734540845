var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:_vm.$style.dataSettings},[_c('page-header',{attrs:{"size":"full","visibleTab":true,"hasBorder":true}},[_c('h1',{attrs:{"slot":"title"},slot:"title"},[_vm._v(" "+_vm._s(_vm.$t('menu.data_settings'))+" "),_c('el-tooltip',{attrs:{"content":_vm.$t('annotation.data_1')}},[_c('pt-icon',{attrs:{"icon":"pt-icon--help-new","icon-style":{
                        width: '16px',
                        height: '16px',
                        fill: '#5E6C84',
                        margin: '0 0 10px 6px'
                    }}})],1)],1),_c('template',{slot:"rightTools"},[_c('router-link',{class:_vm.$style.link,attrs:{"to":{ name: 'CodeOverview' }}},[_c('pt-link',{attrs:{"size":"large"}},[_c('pt-icon',{attrs:{"icon":"pt-icon--loading","icon-style":{
                            width: '16px',
                            height: '16px',
                            fill: '#B3BAC5'
                        }}}),_c('span',[_vm._v(_vm._s(_vm.$t('data_settings.installation')))])],1)],1)],1),_c('pt-tabs',{attrs:{"slot":"tabs","label":"code","theme":"blue","size":"large","value":_vm.activeTab,"tabs":_vm.tabOptions},on:{"change":_vm.handleTabChange},slot:"tabs",scopedSlots:_vm._u([{key:"default",fn:function(slotProps){return [_c('router-link',{attrs:{"to":{ name: slotProps.tab.routerName }}},[_vm._v(_vm._s(slotProps.tab.name))])]}}])})],2),_c('keep-alive',[_c('router-view')],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }