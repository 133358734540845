<template>
    <div :class="$style.dataSettings">
        <page-header size="full" :visibleTab="true" :hasBorder="true">
            <h1 slot="title">
                {{ $t('menu.data_settings') }}
                <el-tooltip :content="$t('annotation.data_1')">
                    <pt-icon
                        icon="pt-icon--help-new"
                        :icon-style="{
                            width: '16px',
                            height: '16px',
                            fill: '#5E6C84',
                            margin: '0 0 10px 6px'
                        }"
                    ></pt-icon>
                </el-tooltip>
            </h1>
            <template slot="rightTools">
                <router-link :class="$style.link" :to="{ name: 'CodeOverview' }">
                    <pt-link size="large">
                        <pt-icon
                            icon="pt-icon--loading"
                            :icon-style="{
                                width: '16px',
                                height: '16px',
                                fill: '#B3BAC5'
                            }"
                        ></pt-icon>
                        <span>{{ $t('data_settings.installation') }}</span>
                    </pt-link>
                </router-link>
            </template>

            <pt-tabs
                slot="tabs"
                label="code"
                theme="blue"
                size="large"
                :value="activeTab"
                :tabs="tabOptions"
                @change="handleTabChange"
            >
                <template v-slot="slotProps">
                    <router-link :to="{ name: slotProps.tab.routerName }">{{ slotProps.tab.name }}</router-link>
                </template>
            </pt-tabs>
        </page-header>

        <keep-alive>
            <router-view></router-view>
        </keep-alive>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import PageHeader from '@/common/packages/page-header/PageHeader';
export default {
    name: 'DataSettings',

    data() {
        const tabOptions = [
            {
                code: 'event',
                default: true,
                name: this.$t('data_settings.event_title'),
                routerName: 'EventSettings'
            },
            {
                code: 'eventProperty',
                name: this.$t('data_settings.event_property_title'),
                routerName: 'EventPropertySettings'
            },
            // {
            //     code: 'conversion',
            //     name: 'Conversion',
            //     routerName: 'ConversionSettings'
            // },
            {
                code: 'userProperty',
                name: this.$t('data_settings.user_property_title'),
                routerName: 'UserPropertySettings'
            }
        ];
        const eventsOptions = [
            {
                name: 'codeless add event',
                code: 'codeless'
            },
            {
                name: 'Code instruction',
                code: 'code'
            }
        ];
        const defaultTab = tabOptions.find(tab => tab.default);
        return {
            tabOptions,
            activeTab: defaultTab.code,
            eventsOptions
        };
    },

    computed: {
        ...mapGetters('main', ['profileInfo'])
    },

    created() {
        const tabConfig = this.tabOptions.find(item => item.routerName === this.$route.name);
        tabConfig && (this.activeTab = tabConfig.code);
    },

    methods: {
        handleTabChange(tab) {
            this.activeTab = (tab && tab.code) || '';
        },

        handleCommand(command) {
            switch (command) {
                case 'codeless':
                    break;
                case 'code':
                    break;
            }
            console.log(command);
        }
    },

    components: {
        PageHeader
    }
};
</script>

<style lang="scss" module>
@import '@/styles/import.scss';

.dataSettings {
    .link {
        @extend %link;
        display: flex;
        align-items: center;

        span {
            margin-left: 8px;
        }
    }

    .tabs {
        display: flex;
        justify-content: space-between;
        margin: 0 auto;

        .btn {
            display: block;
            @include button(big, primary);
        }
    }

    .container {
    }
}
</style>